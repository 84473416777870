import React from "react";

import Youtube from "emersonbroga-youtube-embed/dist/Youtube";
import Subscribe from "src/components/CTA/Subscribe";

import About from "src/sections/About";
// import Warranty from "src/sections/Warranty";
// import FAQ from "src/sections/FAQ";
import Footer from "src/sections/Footer";
import SEO from "src/components/SEO";
// import Course from "src/sections/Course";

// import JavaScriptCourse from "../../data/javascript-course.json";
// import ReactCourse from "../../data/react-node-course.json";
// import faqData from "../../data/faq-react.json";

// import { CHECKOUT_REACT_997 } from "src/helpers/hotmart";
import { CONVERTKIT_FORM_ID_NEWSLETTER } from "src/helpers/config";
import { NAME, BASE_URL } from "src/helpers/config";
// import { isGTNow } from "src/helpers/datetime";

import "../React.scss";

export default () => {
  const seoProps = {
    title: `Curso React e Node JS - ${NAME}`,
    description: "Estão abertas as incrições para o curso de React e Node JS.",
    canonical: `${BASE_URL}/react/`,
    image: `${BASE_URL}/meta/og-image-react-node.jpg`,
  };
  const ctaProps = {
    title: "Quero entrar pra lista de espera",
    secondaryTitle: "Dados pessoais",
    submitLabel: "continuar",
    formId: CONVERTKIT_FORM_ID_NEWSLETTER,
    isModal: false,
  };

  return (
    <div className="react">
      <SEO {...seoProps} />
      <div className="advantages">
        <h1>
          Entre na lista de espera para a próxima turma do Curso de React/NodeJS
        </h1>
        <div className="watch-it">Assista o video até o final</div>
        <div className="wrapper">
          <Youtube videoId="Wt52YiqbllI" />
        </div>
        <Subscribe {...ctaProps} />
      </div>
      {/* <div className="course-content">
        <h2>Conteúdo do Curso</h2>
        <Course courses={[ReactCourse]} />
      </div>
      <div className="course-content bonus">
        <h2>
          <span className="highlight">Bônus</span>
        </h2>

        <Course courses={[JavaScriptCourse]} />
      </div>
      <div className="bonus">
        <div className="inner-container">
          <p>
            Você vai levar também, totalmente grátis o meu livro&nbsp;
            <span className="highlight">
              JavaScript&#58; As novidades do ES6
            </span>
            &nbsp; no formato digital.
          </p>
          <div className="e-book">
            <img
              loading="lazy"
              src="https://emersonbroga.com/wp-content/uploads/2020/12/450x450-template-001.png"
              alt="JavaScript&colon; As novidades do ES6"
            />
          </div>
          <p>
            Após anos e anos trabalhando com JavaScript, eu decidi dedicar meu
            tempo para escrever um livro digital contando as Novidades do ES6.
          </p>
          <p>
            Trazendo um guia prático e de fácil leitura com exemplos claros,
            este e-book veio para esclarecer suas dúvidas quanto a
            arrow-functions, rest operator, spread operator e muito mais. Deixe
            seu código com uma escrita mais limpa e clara, aproveite as novas
            funcionalidades e eleve o seu nível de JavaScript.
          </p>
          <p>
            Se especialize no que o mercado de trabalho está exigindo, seja um
            programador atualizado e mais valorizado.
          </p>
          <p>
            Com o formato digital, você pode ler no kindle, celular, tablet em
            qualquer hora e lugar que desejar.
          </p>

          <img
            loading="lazy"
            className="illustration right"
            src="/static/illustrations/absorbed_in_xahs.svg"
            alt="Livro no formato digital para ler onde você quiser"
          />
        </div>
      </div>

      <Warranty />

      <FAQ data={faqData.data} /> */}

      <About />
      <Footer />
    </div>
  );
};
