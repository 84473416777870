import React, { useState } from "react";
import ConvertKitForm from "../ConvertKitForm";
import { redirectTo } from "src/helpers/route";
import { isSSR } from "src/helpers/gatsby";
import "./CTA.scss";

const Subscribe = ({ title, secondaryTitle, formId, submitLabel, isModal }) => {
  const [showForm, setShowForm] = useState(false);

  const afterSubmit = data => {
    redirectTo("/confirme-seu-email");
    setTimeout(setShowForm, 600, false);
  };

  const isEnter = e => e.keyCode === 13;

  if (showForm && isModal && !isSSR) {
    document.body.classList.add("modal-open");
  } else if (!showForm && isModal && !isSSR) {
    document.body.classList.remove("modal-open");
  }

  if (showForm) {
    return (
      <div className={isModal ? "cta form modal" : "cta form"}>
        <div className="form-wrapper">
          <h6 className="serif text-highlight is-visible">{secondaryTitle}</h6>
          <ConvertKitForm
            form={formId}
            afterSubmit={afterSubmit}
            submitLabel={submitLabel || "Cadastrar"}
          />

          <div
            role="button"
            tabIndex="0"
            className="cancel"
            onClick={e => setShowForm(false)}
            onKeyDown={e => (isEnter(e) ? setShowForm(false) : null)}
          >
            Cancelar
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="cta">
      <button
        onClick={e => setShowForm(true)}
        onKeyDown={e => (isEnter(e) ? setShowForm(true) : null)}
      >
        {title}
      </button>
    </div>
  );
};
export default Subscribe;
