import React from "react";
import "./Footer.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="container">
        <div className="logo">
          <img
            loading="lazy"
            src="https://emersonbroga.com/e/assets/emersonbroga-logo-name-white.png"
            alt="Emerson Broga logo"
          />
        </div>
        <div className="copy">
          <p>
            Os resultados na carreira de cada desenvolvedor podem variar e
            dependem do desempenho dos mesmos.
          </p>

          <p>
            <a href="https://emersonbroga.com/tos" target="blank">
              Termos de uso
            </a>
            &nbsp; – &nbsp;
            <a href="https://emersonbroga.com/pp" target="blank">
              Política de Privacidade
            </a>
            &nbsp;–&nbsp;&reg;&nbsp;Todos os direitos reservados
          </p>
          <p>
            2009 –&nbsp;{year}&nbsp;–&nbsp;
            <a
              href="https://emersonbroga.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              emersonbroga.com
            </a>
            &nbsp;–&nbsp;Code Developer
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
