import React from "react";

import Social from "src/components/Social";

import "./About.scss";

const About = () => {
  return (
    <div className="about">
      <div className="inner-container">
        <div>
          <h2>
            Quem é o <span className="highlight">professor</span>?
          </h2>
          <p>
            Sr. Software Engineer, o Emerson Brôga é formado em Desenvolvimento
            de Sistemas pela Faculdade Pitágoras e pós-graduado em Gestão
            Estratégica de Marketing pela PUC Minas.
          </p>
          <p>
            Trabalhou em diversas agências de publicidade e desenvolvimento de
            software e atualmente está trabalhando em projetos internacionais
            como FOX.com, FXNetworks.com, NatGeo.com entre outros.
          </p>
          <p>
            Atualmente Emerson dedica seu tempo extra a ensinar programação na
            internet, tanto no blog criado em 2011, no canal do youtube e também
            nas redes sociais.
          </p>
          <p>
            Saiba mais em&nbsp;
            <a href="https://emersonbroga.com/e/sobre/">
              emersonbroga.com/sobre/
            </a>
            .
          </p>
        </div>
        <div className="image">
          <img
            loading="lazy"
            src="/images/538x380-broga-la.png"
            alt="Emerson Brôga"
          />
          <Social type="silver" />
        </div>
      </div>
    </div>
  );
};

export default About;
