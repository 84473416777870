import React from "react";
import Icons from "../Icons";

import "./Social.scss";

const Social = ({ type }) => {
  return (
    <ul className={`social ${type}`}>
      <li>
        <a
          className="facebook"
          href="https://www.facebook.com/emersonbrogadev/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons id="facebook" />
        </a>
      </li>
      <li>
        <a
          className="youtube"
          href="https://www.youtube.com/c/emersonbroga/?sub_confirmation=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons id="youtube" />
        </a>
      </li>
      <li>
        <a
          className="instagram"
          href="https://www.instagram.com/emersonbrogadev/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons id="instagram" />
        </a>
      </li>
      <li>
        <a
          className="twitter"
          href="https://www.twitter.com/emersonbroga/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons id="twitter" />
        </a>
      </li>
      <li>
        <a
          className="linkedin"
          href="https://www.linkedin.com/company/13031052/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons id="linkedin" />
        </a>
      </li>
      <li>
        <a
          className="github"
          href="https://github.com/emersonbroga/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons id="github" />
        </a>
      </li>
    </ul>
  );
};

export default Social;
